.navbar-background {
  background-color: var(--accent-light);
  border-radius: 0 0 20px 20px;
  padding: 20px 0;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  position: relative
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.nav-menu {
  /* use if have more than one tab*/
  /* display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 30px; */
}

.nav-links {
  text-decoration: none;
  z-index: 1;
}

.nav-links:hover {
  text-decoration: underline;
}

ul{
  list-style: inside;
  padding-left: 0;
}

.navbar > .active {
  color: var(--accent-dark);
}
  
@media screen and (max-width: 688px) {
  /* .navbar-background {
    padding: 5px 0;
  } */
}