.section-container {
    gap: 20px;
    padding: 50px 0;

    /* display: flex;
    margin: 75px 0;
    flex-wrap: wrap;
    gap: 20px 50px; */
}

.section-right {
    flex: 5;
    min-width: 500px;
}

.section-left {
    min-width: 100px;
    flex: 1;
}

@media screen and (max-width: 688px) {
    .section-right, .section-left {
        min-width: 100%;
        max-width: 100%;
    }
}