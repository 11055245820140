.summary-card {
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-radius: 8px;
    justify-content: space-between;
    min-width: 150px;
    max-width: 300px;
}

.summary-doodle {
    /* background-color: var(--white) !important; */
    width: 80px;
}