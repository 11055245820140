/* about paage styling */
.about-doodle {
    width: 300px;
}

.about-profile {
    background: none;
    max-width: 550px;
    margin: auto;
}

.about-image {
    width: 100%;
}