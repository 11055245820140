@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Inter:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair:ital,opsz,wght@1,5..1200,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

iframe {
  pointer-events: none;
}

* {
  box-sizing: content-box;
  margin: 0px;
}

:root {
  /* define variables */
  --accent-light: #CBC6ED; /* #c51d2e or #ea9554 */
  --accent: #32568A;
  --accent-medium: #8C84C2;
  --accent-dark: #612fcc;
  --accent-hover: #352496;
  --white: #FFFEFE;
  /* --white-dark: #F8F8F8; */
  --white-dark: #f4f2f2;

  --gray: #828282;
  --gray-dark: #4F4F4F;
  --black: #221D30;
  --title: "Manrope";
  --title-fallback: serif;
  --body: "Inter";
  --body-fallback: serif;
  --complex: "Playfair";
  --complex-fallback: serif;
  --simple: "Roboto Mono";
  --simple-fallback: monospace;
}

body {
  background-color: var(--white-dark);
  margin: auto;
  color: var(--black);
}

/* add side margins with this div */
.body {
  padding-left: 40px;
  padding-right: 40px;
  max-width: 1200px;
  margin: auto;
}

.body-small {
  max-width: 800px;
  margin: auto;
}

.separate-section {
  margin: auto;
  background-color: var(--white);
  padding: 24px 36px 24px 36px;;
  border-radius: 10px;
}

.body-media {
  max-width: 1200px;
  margin: auto;
}

#page-container {
  min-height: 100vh;
  position: relative;
}

#content-wrap {
  padding-bottom: 150px;
}

/* customize appearance of text */
.complex-large {
  font-family: var(--complex), var(--complex-fallback);
  font-size: 60px;
  font-style: italic;
  font-weight: 500;
}

.complex-medium {
  font-family: var(--complex), var(--complex-fallback);
  font-size: 34px;
  font-style: italic;
  font-weight: 500;
}

.simple-large {
  font-family: var(--simple), var(--simple-fallback);
  font-style: normal;
  font-weight: 100;
}

h1 {
  font-family: var(--title), var(--title-fallback);
  font-size: 32px;
  font-weight: bold;
}

h2 {
  font-family: var(--body), var(--body-fallback);
  font-size: 24px;
  font-weight: 500;
}

h3 {
  font-family: var(--title), var(--title-fallback);
  font-size: 26px;
  font-weight: bold;
}

h4 {
  font-family: var(--body), var(--body-fallback);
  font-size: 16px;
  padding-left: 0px;
  color: var(--gray);
  font-weight: 400;
  line-height: 1.5;
}

h5 {
  font-family: var(--body), var(--body-fallback);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

h6 {
  font-family: var(--title), var(--title-fallback);
  font-size: 48px;
  font-weight: 500;
}

p, li {
  font-family: var(--body), var(--body-fallback);
  font-size: 14px;
  /* font-weight: 300; */
  line-height: 1.5;
  color: var(--gray-dark);
}

a, li {
  color: var(--black);
}

a:hover {
  color: var(--accent-dark);
}

.tag, button {
  font-family: var(--body), var(--body-fallback);
  font-size: 12px;
  color: var(--gray-dark);
  background-color: var(--white-dark);
  padding: 10px 15px;
  text-decoration: none;
}

.tag {
  border-radius: 5px;
  max-width: max-content;
  display: inline-block;
}

button {
  border-radius: 20px;
  border: 1px solid var(--black);
}

button:hover {
  background-color: var(--accent-light);
}

.small-btn {
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  /* z-index: 9999; */
  font-size: 18px;
}

.divider {
  padding: 10px;
}

.divider-big {
  padding: 40px;
}

hr {
  margin: 25px 0;
  border: 1px solid var(--accent-light);
  top: 20px;
}


.unbold {
  font-weight: 400;
  display: inline-block;
}


/* two column structure */
.row, .row-2{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

/* align items at center, used in project card view */
.row {
  align-items: center;
  gap: 10px 40px;
}

/* align items at top, used in project detail view */
.row-2 {
  align-items: flex-start;
  gap: 20px 50px;
}

/* wrap items two-by-two */
.row-3 {
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 20px;
}

.col {
  flex: 1;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  min-width: 360px;
}

.column > img {
  width: 100%; 
  min-width: 400px;
  height: auto; 
  border-radius: 5px;
}

.column > * {
  margin: 10px 0;
}

.col-big {
  flex: 2;
  min-width: 400px;
}

.col-small {
  min-width: 300px;
}

/* image above text when wrapped */
.reverse {
  flex-wrap: wrap-reverse;
}

.image-fade {
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1.0) 50%, transparent 100%);
}

@media screen and (max-width: 688px) {

  .body {
    padding-left: 20px;
    padding-right: 20px;
  }

  h1 {
    font-size: 24px;
  }
  
  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 24px;
  }

  h4, h5 {
    font-size: 14px;
  }

  h6 {
    font-size: 36px;
  }

  p, .tag {
    font-size: 14px;
  }

  .column, .column > img {
    min-width: 100%;
    max-width: 100%;
  }

  .col {
    flex-basis: 25%;
  }

  .tag {
    font-size: 10px;
  }

  .complex-large {
    font-size: 46px;
  }

  .complex-medium {
    font-size: 30px;
  }
}