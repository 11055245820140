#dev-body {
    background-color: var(--black);
    color: var(--white);
    padding: 40px 0;
    z-index: -1;
}

#dev-body .tag {
    background-color: var(--gray-dark);
    color: var(--white);
}