.intro-container {
    display: flex;
    flex-direction: column;
    padding-top: 75px;
    padding-bottom: 75px;
    gap: 24px;
}

.intro-container h4 {
    padding-bottom: 8px;
    padding-top: 16px;
}

.intro-img {
    height: 100%;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    background-color: var(--white-dark);
    border-radius: 5px;
}

.intro-btn {
    top: 20px;
    position: -webkit-sticky;
    position: sticky;
}