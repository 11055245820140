.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.popup-inner {
    position: relative;
    padding: 30px;
    height: 80%;
    background-color: var(--white-dark);
    border-radius: 20px;
}

.popup-inner video {
    max-height: 100%;
}

.popup-button {
    top: 16px;
    right: 16px;
}