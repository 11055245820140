@import url(https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Inter:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair:ital,opsz,wght@1,5..1200,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap);
.navbar-background {
  background-color: var(--accent-light);
  border-radius: 0 0 20px 20px;
  padding: 20px 0;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  position: relative
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.nav-menu {
  /* use if have more than one tab*/
  /* display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 30px; */
}

.nav-links {
  text-decoration: none;
  z-index: 1;
}

.nav-links:hover {
  text-decoration: underline;
}

ul{
  list-style: inside;
  padding-left: 0;
}

.navbar > .active {
  color: var(--accent-dark);
}
  
@media screen and (max-width: 688px) {
  /* .navbar-background {
    padding: 5px 0;
  } */
}
.footer-background {
  width: 100%;
  background-color: var(--accent-light);
  border-radius: 20px 20px 0 0;
  position: absolute;
  bottom: 0;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  padding: 50px 0;
}

@media screen and (max-width: 688px) {

  .footer-container {
    display: block;
    padding: 25px 0;
  }

  .footer-right {
    padding-top: 30px;
  }
}
/* Social Icons */
.social-icon-link {
    color:var(--black);
    font-size: 24px;
}

.social-media {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 150px;
    padding-top: 10px;
}

@media screen and (max-width: 688px) {

    .social-media {
        width: 130px;
    }
}
iframe {
  pointer-events: none;
}

* {
  box-sizing: content-box;
  margin: 0px;
}

:root {
  /* define variables */
  --accent-light: #CBC6ED; /* #c51d2e or #ea9554 */
  --accent: #32568A;
  --accent-medium: #8C84C2;
  --accent-dark: #612fcc;
  --accent-hover: #352496;
  --white: #FFFEFE;
  /* --white-dark: #F8F8F8; */
  --white-dark: #f4f2f2;

  --gray: #828282;
  --gray-dark: #4F4F4F;
  --black: #221D30;
  --title: "Manrope";
  --title-fallback: serif;
  --body: "Inter";
  --body-fallback: serif;
  --complex: "Playfair";
  --complex-fallback: serif;
  --simple: "Roboto Mono";
  --simple-fallback: monospace;
}

body {
  background-color: #f4f2f2;
  background-color: var(--white-dark);
  margin: auto;
  color: #221D30;
  color: var(--black);
}

/* add side margins with this div */
.body {
  padding-left: 40px;
  padding-right: 40px;
  max-width: 1200px;
  margin: auto;
}

.body-small {
  max-width: 800px;
  margin: auto;
}

.separate-section {
  margin: auto;
  background-color: #FFFEFE;
  background-color: var(--white);
  padding: 24px 36px 24px 36px;;
  border-radius: 10px;
}

.body-media {
  max-width: 1200px;
  margin: auto;
}

#page-container {
  min-height: 100vh;
  position: relative;
}

#content-wrap {
  padding-bottom: 150px;
}

/* customize appearance of text */
.complex-large {
  font-family: "Playfair", serif;
  font-family: var(--complex), var(--complex-fallback);
  font-size: 60px;
  font-style: italic;
  font-weight: 500;
}

.complex-medium {
  font-family: "Playfair", serif;
  font-family: var(--complex), var(--complex-fallback);
  font-size: 34px;
  font-style: italic;
  font-weight: 500;
}

.simple-large {
  font-family: "Roboto Mono", monospace;
  font-family: var(--simple), var(--simple-fallback);
  font-style: normal;
  font-weight: 100;
}

h1 {
  font-family: "Manrope", serif;
  font-family: var(--title), var(--title-fallback);
  font-size: 32px;
  font-weight: bold;
}

h2 {
  font-family: "Inter", serif;
  font-family: var(--body), var(--body-fallback);
  font-size: 24px;
  font-weight: 500;
}

h3 {
  font-family: "Manrope", serif;
  font-family: var(--title), var(--title-fallback);
  font-size: 26px;
  font-weight: bold;
}

h4 {
  font-family: "Inter", serif;
  font-family: var(--body), var(--body-fallback);
  font-size: 16px;
  padding-left: 0px;
  color: #828282;
  color: var(--gray);
  font-weight: 400;
  line-height: 1.5;
}

h5 {
  font-family: "Inter", serif;
  font-family: var(--body), var(--body-fallback);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

h6 {
  font-family: "Manrope", serif;
  font-family: var(--title), var(--title-fallback);
  font-size: 48px;
  font-weight: 500;
}

p, li {
  font-family: "Inter", serif;
  font-family: var(--body), var(--body-fallback);
  font-size: 14px;
  /* font-weight: 300; */
  line-height: 1.5;
  color: #4F4F4F;
  color: var(--gray-dark);
}

a, li {
  color: #221D30;
  color: var(--black);
}

a:hover {
  color: #612fcc;
  color: var(--accent-dark);
}

.tag, button {
  font-family: "Inter", serif;
  font-family: var(--body), var(--body-fallback);
  font-size: 12px;
  color: #4F4F4F;
  color: var(--gray-dark);
  background-color: #f4f2f2;
  background-color: var(--white-dark);
  padding: 10px 15px;
  text-decoration: none;
}

.tag {
  border-radius: 5px;
  max-width: -webkit-max-content;
  max-width: max-content;
  display: inline-block;
}

button {
  border-radius: 20px;
  border: 1px solid #221D30;
  border: 1px solid var(--black);
}

button:hover {
  background-color: #CBC6ED;
  background-color: var(--accent-light);
}

.small-btn {
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  /* z-index: 9999; */
  font-size: 18px;
}

.divider {
  padding: 10px;
}

.divider-big {
  padding: 40px;
}

hr {
  margin: 25px 0;
  border: 1px solid #CBC6ED;
  border: 1px solid var(--accent-light);
  top: 20px;
}


.unbold {
  font-weight: 400;
  display: inline-block;
}


/* two column structure */
.row, .row-2{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

/* align items at center, used in project card view */
.row {
  align-items: center;
  grid-gap: 10px 40px;
  gap: 10px 40px;
}

/* align items at top, used in project detail view */
.row-2 {
  align-items: flex-start;
  grid-gap: 20px 50px;
  gap: 20px 50px;
}

/* wrap items two-by-two */
.row-3 {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  grid-gap: 20px;
  gap: 20px;
}

.col {
  flex: 1 1;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1 1;
  min-width: 360px;
}

.column > img {
  width: 100%; 
  min-width: 400px;
  height: auto; 
  border-radius: 5px;
}

.column > * {
  margin: 10px 0;
}

.col-big {
  flex: 2 1;
  min-width: 400px;
}

.col-small {
  min-width: 300px;
}

/* image above text when wrapped */
.reverse {
  flex-wrap: wrap-reverse;
}

.image-fade {
  -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1.0) 50%, transparent 100%);
          mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1.0) 50%, transparent 100%);
}

@media screen and (max-width: 688px) {

  .body {
    padding-left: 20px;
    padding-right: 20px;
  }

  h1 {
    font-size: 24px;
  }
  
  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 24px;
  }

  h4, h5 {
    font-size: 14px;
  }

  h6 {
    font-size: 36px;
  }

  p, .tag {
    font-size: 14px;
  }

  .column, .column > img {
    min-width: 100%;
    max-width: 100%;
  }

  .col {
    flex-basis: 25%;
  }

  .tag {
    font-size: 10px;
  }

  .complex-large {
    font-size: 46px;
  }

  .complex-medium {
    font-size: 30px;
  }
}
#dev-body {
    background-color: var(--black);
    color: var(--white);
    padding: 40px 0;
    z-index: -1;
}

#dev-body .tag {
    background-color: var(--gray-dark);
    color: var(--white);
}
.card-container {
    padding: 20px 40px;
    position: relative;
    overflow: hidden;
    margin: 100px 0;
    background: var(--white);
    box-shadow: calc(100vw / 3 * -1) 0 var(--white);
    border-radius: 0 10px 10px 0;
}

.card-container-2 {
    padding: 15px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.card-container-2 p {
    color: var(--accent-light);
}

.card-container:hover {
    color: var(--black);
    background-color: var(--accent-light);
    -webkit-appearance: none;
    box-shadow: calc(100vw / 3 * -1) 0 var(--accent-light);
    transition: box-shadow 750ms, background-color 750ms;
}

.card-link {
    text-decoration: none;
    color: var(--black);
}

/* surprise gif */
.card-cat {
    display: none;
    position: absolute;
    right: 10px;
    bottom: 0;
    max-width: 200px;
    height: auto;
}

.card-container:hover .card-cat {
    display: block;
    -webkit-animation-name : mymove;
            animation-name : mymove;
    -webkit-animation-duration : 1s;
            animation-duration : 1s;
}

@-webkit-keyframes mymove {
    from {-webkit-transform: translateY(50px);transform: translateY(50px);}
    to {-webkit-transform: translateY(0px);transform: translateY(0px);}
}

@keyframes mymove {
    from {-webkit-transform: translateY(50px);transform: translateY(50px);}
    to {-webkit-transform: translateY(0px);transform: translateY(0px);}
}


/* tag container */
.featured-tags {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    flex-wrap: wrap;
}

.card-img {
    background-color: var(--white-dark);
}


@media screen and (min-width: 890px) {
    /* align text to the right when image is on the right */
    .align-right {
        margin-left: auto; 
        margin-right: 0;
        text-align: right;
    }
    .align-right > .featured-tags {
        justify-content: flex-end;
    }
}

@media screen and (max-width: 688px) {
    .card-container {
        padding: 10px;
        position: relative;
    }
}
.purple-tag {
    background: var(--accent-lighter);
    padding: 5px 10px;
    border-radius: 5px;
    max-width: -webkit-max-content;
    max-width: max-content;
    margin: 0px 15px 10px 0;
    display: inline-block;
    font-family: var(--body), var(--body-fallback);
    font-size: 10px;
}
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.popup-inner {
    position: relative;
    padding: 30px;
    height: 80%;
    background-color: var(--white-dark);
    border-radius: 20px;
}

.popup-inner video {
    max-height: 100%;
}

.popup-button {
    top: 16px;
    right: 16px;
}
/* home styling */
#home-background {
    width: 100%;
    background-color: var(--accent-light);
    border-radius: 0 0 20px 20px;
    position: relative; 
    top: -75px;
}

.home-gif {
    top: 20px;
    max-width: 300px;
    height: auto;
    position: absolute;
}

.page-body {
    padding-bottom:80px;
    padding-top: 30px;
}

/* .lab-links a {
    color: var(--accent-dark);
} */

.no-underline {
    text-decoration: none;
}

.dev-link-container {
    color: var(--white);
    background-color: var(--black);
    border-radius: 0 10px 10px 0;
    -webkit-appearance: none;
    box-shadow: calc(100vw / 3 * -1) 0 var(--black);
}

.dev-link {
    padding: 20px 40px;
}

.dev-link-container:hover {
    background-color: var(--accent-hover);
    border-radius: 0 10px 10px 0;
    -webkit-appearance: none;
    box-shadow: calc(100vw / 3 * -1) 0 var(--accent-hover);
    transition: box-shadow 750ms, background-color 750ms;
}

.dev-tags {
    padding-top: 20px;
}

.dev-tags .tag {
    background-color: var(--gray-dark);
    color: var(--white);
}

.dev-img {
    width: 97%;
}

/* resume page styling */
.right-align {
    float: right;
    z-index: 1;
} 

.clear-float {
    clear: both;
}
.ux-projects {
    /* margin: 25px 0; */
}
.summary-card {
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    gap: 12px;
    border-radius: 8px;
    justify-content: space-between;
    min-width: 150px;
    max-width: 300px;
}

.summary-doodle {
    /* background-color: var(--white) !important; */
    width: 80px;
}
/* about paage styling */
.about-doodle {
    width: 300px;
}

.about-profile {
    background: none;
    max-width: 550px;
    margin: auto;
}

.about-image {
    width: 100%;
}
/* font styling */
.resume-container h1, h2, h3, h4 {
    font-family: var(--title), var(--title-fallback);
}

.resume-container h1 {
    font-size: 24px;
    color: var(--black);
}

.resume-container h2 {
    font-size: 18px;
    color: var(--accent-medium);
    font-weight: 600;
}

.resume-container h3 {
    font-size: 18px;
    color: var(--black);
    font-weight: 600;
}

.resume-container h4 {
    font-size: 18px;
    color: var(--gray);
    font-weight: 600;
}

.resume-container h5 {
    font-size: 14px;
    color: var(--gray);
}

.resume-container p, li {
    color: var(--gray-dark);
}

/* space styling */
.resume-container {
    display: flex;
    flex-direction: column;
    grid-gap: 42px;
    gap: 42px;
    flex-wrap: wrap;
}

.space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    grid-gap: 4px 16px;
    gap: 4px 16px;
}

.resume-category {
    display: flex;
    flex-direction: column;
    grid-gap: 24px;
    gap: 24px;
}

.resume-section {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
}

.titles {
    align-items: top;
    display: flex;
    flex-direction: row;
    grid-gap: 0px 10px;
    gap: 0px 10px;
    flex-wrap: wrap;
}

.resume-container ul {
    list-style: outside;
    padding-left: 20px;
}
.intro-container {
    display: flex;
    flex-direction: column;
    padding-top: 75px;
    padding-bottom: 75px;
    grid-gap: 24px;
    gap: 24px;
}

.intro-container h4 {
    padding-bottom: 8px;
    padding-top: 16px;
}

.intro-img {
    height: 100%;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    background-color: var(--white-dark);
    border-radius: 5px;
}

.intro-btn {
    top: 20px;
    position: -webkit-sticky;
    position: sticky;
}
.section-container {
    grid-gap: 20px;
    gap: 20px;
    padding: 50px 0;

    /* display: flex;
    margin: 75px 0;
    flex-wrap: wrap;
    gap: 20px 50px; */
}

.section-right {
    flex: 5 1;
    min-width: 500px;
}

.section-left {
    min-width: 100px;
    flex: 1 1;
}

@media screen and (max-width: 688px) {
    .section-right, .section-left {
        min-width: 100%;
        max-width: 100%;
    }
}
.subsection {
    margin-bottom: 50px;
}

.subsection-body {
    max-width: 700px;
    /* margin: auto; */
}

/* .subsection-img {
    max-width: 700px;
    border-radius: 10px;
    display: block;
    margin-bottom: 20px;
} */

/* full width image */
.subsection-img {
    max-width: 100%;
    border-radius: 10px;
}

/* numbered list */
.numbered-list li {
    font-family: var(--body), var(--body-fallback);
    font-size: 16px;
    font-weight: 400;
    padding: 5px 0;
}

ol.numbered-list {
    list-style-type: none;
    margin: 0;
    margin-left: 1em;
    padding: 0;
}

ol.numbered-list li {
    counter-increment: step-counter;
    padding-left: 3em;
    text-indent: 2em;
}

ol.numbered-list li:not(:last-child) {
    margin-bottom: 1em;
}

ol.numbered-list li:before {
    content: counter(step-counter);
    background-color: var(--accent-light);
    font-weight: bold;
    padding: 5px 12px;
    height: 32px;
    width: 32px;
    border-radius: 100%;
    margin-right: 1em;
    margin-left: -5em;
}

/* quotes */
.subsection-quotes p {
    font-family: var(--body), var(--body-fallback);
    font-size: 16px;
    font-weight: 400;
    background-color: var(--accent-light);
    color: var(--black);
    padding: 15px 20px;
    border-radius: 10px;
}

/* videos within project detail page */
.project-video {
    max-width: 300px;
}

@media screen and (max-width: 688px) {
    .subsection-img {
        max-width: 100%;
    }

    .numbered-list li {
        font-size: 12px;
    }

    ol.numbered-list li:before {
        padding: 5px 9px;
        height: 32px;
        width: 32px;
        border-radius: 100%;
        margin-right: 1em;
        margin-left: -5em;
    }

    .subsection-quotes p {
        font-size: 12px;
    }
}
