/* home styling */
#home-background {
    width: 100%;
    background-color: var(--accent-light);
    border-radius: 0 0 20px 20px;
    position: relative; 
    top: -75px;
}

.home-gif {
    top: 20px;
    max-width: 300px;
    height: auto;
    position: absolute;
}

.page-body {
    padding-bottom:80px;
    padding-top: 30px;
}

/* .lab-links a {
    color: var(--accent-dark);
} */

.no-underline {
    text-decoration: none;
}

.dev-link-container {
    color: var(--white);
    background-color: var(--black);
    border-radius: 0 10px 10px 0;
    -webkit-appearance: none;
    box-shadow: calc(100vw / 3 * -1) 0 var(--black);
}

.dev-link {
    padding: 20px 40px;
}

.dev-link-container:hover {
    background-color: var(--accent-hover);
    border-radius: 0 10px 10px 0;
    -webkit-appearance: none;
    box-shadow: calc(100vw / 3 * -1) 0 var(--accent-hover);
    transition: box-shadow 750ms, background-color 750ms;
}

.dev-tags {
    padding-top: 20px;
}

.dev-tags .tag {
    background-color: var(--gray-dark);
    color: var(--white);
}

.dev-img {
    width: 97%;
}

/* resume page styling */
.right-align {
    float: right;
    z-index: 1;
} 

.clear-float {
    clear: both;
}