.footer-background {
  width: 100%;
  background-color: var(--accent-light);
  border-radius: 20px 20px 0 0;
  position: absolute;
  bottom: 0;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  padding: 50px 0;
}

@media screen and (max-width: 688px) {

  .footer-container {
    display: block;
    padding: 25px 0;
  }

  .footer-right {
    padding-top: 30px;
  }
}