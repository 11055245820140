.card-container {
    padding: 20px 40px;
    position: relative;
    overflow: hidden;
    margin: 100px 0;
    background: var(--white);
    box-shadow: calc(100vw / 3 * -1) 0 var(--white);
    border-radius: 0 10px 10px 0;
}

.card-container-2 {
    padding: 15px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.card-container-2 p {
    color: var(--accent-light);
}

.card-container:hover {
    color: var(--black);
    background-color: var(--accent-light);
    -webkit-appearance: none;
    box-shadow: calc(100vw / 3 * -1) 0 var(--accent-light);
    transition: box-shadow 750ms, background-color 750ms;
}

.card-link {
    text-decoration: none;
    color: var(--black);
}

/* surprise gif */
.card-cat {
    display: none;
    position: absolute;
    right: 10px;
    bottom: 0;
    max-width: 200px;
    height: auto;
}

.card-container:hover .card-cat {
    display: block;
    animation-name : mymove;
    animation-duration : 1s;
}

@keyframes mymove {
    from {transform: translateY(50px);}
    to {transform: translateY(0px);}
}


/* tag container */
.featured-tags {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.card-img {
    background-color: var(--white-dark);
}


@media screen and (min-width: 890px) {
    /* align text to the right when image is on the right */
    .align-right {
        margin-left: auto; 
        margin-right: 0;
        text-align: right;
    }
    .align-right > .featured-tags {
        justify-content: flex-end;
    }
}

@media screen and (max-width: 688px) {
    .card-container {
        padding: 10px;
        position: relative;
    }
}